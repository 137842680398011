<template src="./CLoading.html"></template>

<script lang="ts">
export default {
  name: "CLoading",
  props: {
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cssClass() {
      return {
        "CLoading--icon": this.icon,
      };
    },
  },
};
</script>

<style lang="scss" src="./CLoading.scss" />
