<template>
  <CLoading v-if="state.isLoading" />
  <Error v-if="state.errorMessage" :message="state.errorMessage" />
</template>

<script>
import { reactive } from "vue";
import useFetch from "./use/useFetch";
import Error from "./components/modules/Error.vue";
import CLoading from "./components/modules/CLoading/CLoading.vue";

export default {
  name: "App",
  components: {
    CLoading,
    Error,
  },
  setup() {
    const { $get, loading } = useFetch();
    const state = reactive({
      link: "",
      isLoading: true,
      errorMessage: "",
    });

    async function fetchLink() {
      state.isLoading = true;
      const linkSlug = location.pathname.substring(1);
      if (linkSlug) {
        try {
          const { link, message } = await $get(`/links/${linkSlug}`);
          if (link) {
            window.location.replace(link);
          } else if (message) {
            state.errorMessage = message;
          } else {
            state.errorMessage = "Not found";
          }
        } catch (error) {
          const result = await error.json();
          state.errorMessage = result.data.message || "Error";
        }
      } else {
        state.errorMessage = "Not found";
      }
      state.isLoading = false;
    }

    fetchLink();

    return {
      loading,
      state,
    };
  },
};
</script>
