<template>
  <div class="Error">
    <h3>{{ message }}</h3>
  </div>
</template>

<script>
export default {
  name: "Error",
  setup() {},
  props: {
    message: String,
  },
};
</script>

<style lang="scss">
.Error {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  h3 {
    font-size: 18px;
  }
}
</style>
