import { createApp } from "vue";
import App from "./App.vue";

import "./registerServiceWorker";
import "./assets/scss/index.scss";
import "./assets/css/variables.css";

const app = createApp(App);

app.mount("#link");
